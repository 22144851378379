import { useCallback, useEffect, useState } from 'react';
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  getDocs,
  collection,
  Timestamp,
} from 'firebase/firestore';
import { useAuthStore } from '@/stores/AuthStore';
import moment from 'moment';
import { cleanUserData } from '@/utils/cleanUserData';

export const useUserData = () => {
  const firebaseApp = useAuthStore((state) => state.firebaseApp);
  const firestore = useAuthStore((state) => state.firestore);
  const user = useAuthStore((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (error) {
      console.error('useUserData error:', error);
    }
  }, [error]);

  const getUserData = async () => {
    if (!user || !firestore) {
      return;
    }

    setError(null);
    setLoading(true);

    try {
      const docRef = doc(firestore, 'users', user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setLoading(false);
        return docSnap.data();
      } else {
        console.error('No such document!');
      }
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  const createUserData = async (userData) => {
    const cleanedUserData = cleanUserData(userData);

    setError(null);
    setLoading(true);

    try {
      const docRef = doc(firestore, 'users', user.uid);
      await setDoc(docRef, cleanedUserData);
      setLoading(false);
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  const updateUserData = async (data) => {
    setLoading(true);

    try {
      const docRef = doc(firestore, 'users', user.uid);
      await updateDoc(docRef, data);
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  const getAllUsers = async () => {
    setLoading(true);

    try {
      const querySnapshot = await getDocs(collection(firestore, 'users'));
      const users = [];
      querySnapshot.forEach((doc) => {
        users.push({ id: doc.id, ...doc.data() });
      });
      setLoading(false);
      return users;
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  return {
    loading,
    error,
    getUserData,
    createUserData,
    updateUserData,
    getAllUsers,
  };
};
