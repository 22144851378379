import { Avatar, Stack, Typography, Link, Box } from '@mui/material';
import { SUBTITLE } from '@/pages/app';
import Island from '@/components/IslandContainer';
import { blueGrey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { useUserData } from '@/hooks/useUserData';
import { useAuth } from '@/hooks/useAuth';
import moment from 'moment';

export default function SideBar() {
  const [userData, setUserData] = useState(null);
  const { getUserData } = useUserData();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        const userData = await getUserData();
        setUserData(userData);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <Box width={240}>
      <Island>
        <Stack spacing={1} alignItems="center">
          <Link href="/app/person">
            <Avatar src={userData?.profilePicture || undefined} />
          </Link>
          <Stack>
            <Typography variant="body2" color={blueGrey[800]}>
              {userData?.firstName} {userData?.familyName}
            </Typography>
            <Typography variant="caption" color={SUBTITLE}>
              {userData
                ? moment
                    .unix(userData.dateOfBirth?.seconds)
                    .format('D MMM YYYY')
                : null}
            </Typography>
          </Stack>
        </Stack>
        <Stack display="flex" direction="column" spacing={1}>
          {userData?.marriageStatus === 'married' && (
            <Stack>
              <Typography
                variant="caption"
                color={blueGrey[800]}
                fontWeight={600}
              >
                Spouse
              </Typography>
              <Typography variant="body2" color={blueGrey[800]}>
                {userData?.spouseFirstName}
              </Typography>
            </Stack>
          )}
          {userData?.children?.length > 0 && (
            <Stack>
              <Typography
                variant="caption"
                color={blueGrey[800]}
                fontWeight={600}
              >
                Children
              </Typography>
              {userData.children?.map((child, i) => (
                <Typography key={i} variant="body2" color={blueGrey[800]}>
                  {child.firstName}
                </Typography>
              ))}
            </Stack>
          )}
        </Stack>
      </Island>
    </Box>
  );
}
