import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useAuthStore } from '@/stores/AuthStore';
import { useEffect, useState } from 'react';
import uniqid from 'uniqid';

export const useImage = () => {
  const storage = useAuthStore((state) => state.storage);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (error) {
      console.error('useImage error:', error);
    }
  }, [error]);

  const uploadImage = async (file) => {
    try {
      setError(null);
      setLoading(true);
      const name = `${uniqid()}.${file.name.split('.').pop()}`;
      const storageRef = ref(storage, `userGenerated/${name}`);
      await uploadBytes(storageRef, file);
      return await getDownloadURL(storageRef);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  return {
    uploadImage,
    loading,
    error,
  };
};
