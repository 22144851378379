import {
  query,
  orderBy,
  limit,
  collection,
  getDocs,
  startAfter,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { useAuthStore } from '@/stores/AuthStore';
import { useEffect, useState } from 'react';
import { useAuth } from './useAuth';

export const usePosts = () => {
  const firestore = useAuthStore((state) => state.firestore);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (error) {
      console.error('usePosts error:', error);
    }
  }, [error]);

  const getAllPosts = async (max = 10, offset) => {
    setError(null);
    setLoading(true);

    try {
      const ref = collection(firestore, 'posts');
      let q = query(ref, orderBy('publishedAt', 'desc'));

      if (max) {
        q = query(q, limit(max));
      }

      if (offset) {
        q = query(q, startAfter(offset));
      }

      const docSnaps = await getDocs(q);

      setLoading(false);
      return docSnaps.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (e) {
      setError(e);
    }
  };

  const getPost = async (postId) => {
    setError(null);
    setLoading(true);

    const docSnap = await getDoc(doc(firestore, 'posts', id));
    setLoading(false);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      setError('Post does not exist');
    }
  };

  const getPostCount = async () => {
    setError(null);
    setLoading(true);

    try {
      const ref = collection(firestore, 'posts');
      const q = query(ref);
      const snapshot = await getCountFromServer(q);

      setLoading(false);

      return snapshot.data().count;
    } catch (e) {
      setError(e);
    }
  };

  const createPost = async (post) => {
    setError(null);
    setLoading(true);

    try {
      const docRef = await addDoc(collection(firestore, 'posts'), {
        ...post,
        publishedAt: serverTimestamp(),
      });

      setLoading(false);
      return docRef.id;
    } catch (e) {
      setError(e);
    }
  };

  const updatePost = async (postId, post) => {
    setError(null);
    setLoading(true);

    try {
      await updateDoc(doc(firestore, 'posts', postId), {
        ...post,
        updatedAt: serverTimestamp(),
      });

      setLoading(false);
    } catch (e) {
      setError(e);
    }
  };

  const deletePost = async (postId) => {
    setError(null);
    setLoading(true);

    try {
      await deleteDoc(doc(firestore, 'posts', postId));
      setLoading(false);
    } catch (e) {
      setError(e);
    }
  };

  return {
    getAllPosts,
    getPost,
    getPostCount,
    createPost,
    updatePost,
    deletePost,
    loading,
    error,
  };
};
