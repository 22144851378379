import { Box } from '@mui/material';

export default function AdminStripe() {
  return (
    <Box
      height={4}
      sx={{
        background:
          'linear-gradient(45deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)',
      }}
    />
  );
}
