import { useImage } from '@/hooks/useImage';
import { Stack, Typography, colors, styled } from '@mui/material';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

export const FileDropzone = ({ onFileUpload }) => {
  const { uploadImage } = useImage();

  const uploadFunc = useCallback(
    async (file) => {
      const url = await uploadImage(file);
      return url;
    },
    [uploadImage]
  );

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const results = await Promise.all(acceptedFiles.map(uploadFunc));
      onFileUpload(results);
    },
    [onFileUpload, uploadFunc]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
    },
  });

  return (
    <FileDropContainer {...getRootProps()} isDragActive={isDragActive}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Typography color="#fff">Drop the files here ...</Typography>
      ) : (
        <Typography color={colors.blueGrey['400']}>
          Drag and drop some files here, or click to select files
        </Typography>
      )}
    </FileDropContainer>
  );
};

const FileDropContainer = styled(Stack)(({ theme, isDragActive }) => ({
  padding: theme.spacing(2),
  backgroundColor: isDragActive ? colors.blue['500'] : colors.blueGrey['50'],
  borderRadius: theme.spacing(1),
  cursor: 'pointer',
  height: 80,
  alignItems: 'center',
  justifyContent: 'center',
}));
