import { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Button,
  DialogActions,
  Divider,
  Stack,
  Grid,
} from '@mui/material';
import Island from './IslandContainer';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import moment from 'moment';
import { blueGrey } from '@mui/material/colors';
import PublishPost from './PublishPost';
import { usePosts } from '@/hooks/usePosts';
import AdminStripe from '@/components/AdminStripe';
import LoadingButton from '@mui/lab/LoadingButton';
import ImgsViewer from 'react-images-viewer';
import { useUserRoles } from '@/hooks/useUserRoles';

const SUBTITLE = blueGrey[500];

export default function PostItem({ post, updatePosts }) {
  const { updatePost, deletePost } = usePosts();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageView, setImageView] = useState(null);
  const { isAdmin } = useUserRoles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditModalOpen = () => setOpenEditModal(true);
  const handleEditModalClose = () => setOpenEditModal(false);
  const handleDeleteModalOpen = () => setOpenDeleteModal(true);
  const handleDeleteModalClose = () => {
    setOpenDeleteModal(false);
    handleMenuClose();
  };

  const handleUpdatePost = async (newContent, event, images) => {
    const updatedPost = {
      ...post,
      content: newContent,
      event,
      images,
    };
    await updatePost(post.id, updatedPost);
    handleEditModalClose();
    handleMenuClose();
    updatePosts();
  };

  const handleOnCancel = () => {
    handleEditModalClose();
    handleMenuClose();
  };

  const handleDeletePost = async () => {
    setIsLoading(true);
    await deletePost(post.id);
    updatePosts();
    setIsLoading(false);
  };

  return (
    <>
      <Island>
        <Box display="flex">
          <Stack flex={1} spacing={2}>
            <Box>
              <Typography color={SUBTITLE} variant="body2">
                Posted {moment.unix(post.publishedAt.seconds).fromNow()}
              </Typography>
              <Typography
                color={blueGrey[800]}
                dangerouslySetInnerHTML={{
                  __html: post.content.replaceAll(/\n/g, '<br />'),
                }}
              />
            </Box>
            {post.images?.length > 0 && (
              <>
                <Divider />
                <Box>
                  <Grid container spacing={1}>
                    {post.images.map((image, index) => (
                      <Grid item key={index} xs={3}>
                        <Box
                          onClick={() => {
                            setImageView(index);
                          }}
                          sx={{
                            backgroundImage: `url(${image})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            height: 160,
                            borderRadius: 2,
                            cursor: 'pointer',
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                <ImgsViewer
                  imgs={post.images.map((image) => ({ src: image }))}
                  currImg={imageView || 0}
                  backdropCloseable={true}
                  onClickPrev={() => {
                    setImageView((prev) => prev - 1);
                  }}
                  onClickNext={() => {
                    setImageView((prev) => prev + 1);
                  }}
                  isOpen={imageView !== null}
                  onClose={() => {
                    setImageView(null);
                  }}
                />
              </>
            )}
            {post.event && (
              <>
                <Divider />
                <Box pt={1}>
                  <Typography
                    color={blueGrey[800]}
                    variant="body2"
                    fontWeight="bold"
                  >
                    What: {post.event.name}
                  </Typography>
                  <Typography
                    color={blueGrey[800]}
                    variant="body2"
                    fontWeight="bold"
                  >
                    When:{' '}
                    {moment
                      .unix(post.event.startDate.seconds)
                      .format('DD MMM YYYY')}{' '}
                    -{' '}
                    {moment
                      .unix(post.event.endDate.seconds)
                      .format('DD MMM YYYY')}
                  </Typography>
                  <Typography
                    color={blueGrey[800]}
                    variant="body2"
                    fontWeight="bold"
                  >
                    Where: {post.event.location}
                  </Typography>
                </Box>
              </>
            )}
          </Stack>
          {isAdmin && (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreHorizIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleEditModalOpen}>Edit Post</MenuItem>
                <MenuItem
                  onClick={() => {
                    handleDeleteModalOpen();
                  }}
                >
                  Delete Post
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Box>
      </Island>
      <Dialog
        open={openEditModal}
        onClose={handleEditModalClose}
        maxWidth="sm"
        fullWidth
      >
        <AdminStripe />
        <DialogTitle>Edit Post</DialogTitle>
        <DialogContent>
          <PublishPost
            defaultContent={post.content}
            defaultEvent={post.event}
            defaultImages={post.images}
            publish={handleUpdatePost}
            isUpdate={true}
            onCancel={handleOnCancel}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDeleteModal}
        onClose={handleDeleteModalClose}
        maxWidth="sm"
        fullWidth
      >
        <AdminStripe />
        <DialogTitle>Delete Post?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you wnat to delete this post?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="inherit"
            onClick={handleDeleteModalClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="error"
            onClick={handleDeletePost}
            loading={isLoading}
          >
            Delete Post
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
