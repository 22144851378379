import { doc, getDoc } from 'firebase/firestore';
import { useAuthStore } from '@/stores/AuthStore';
import { useCallback, useEffect, useState } from 'react';

export const useUserRoles = () => {
  const firestore = useAuthStore((state) => state.firestore);
  const user = useAuthStore((state) => state.user);
  const [isAdmin, setIsAdmin] = useState(false);
  const [fetched, setFetched] = useState(false);

  const getUserRole = useCallback(async () => {
    try {
      const docSnap = await getDoc(doc(firestore, 'roles', user.uid));
      if (docSnap.exists()) {
        const data = docSnap.data();
        setIsAdmin(!!data.admin);
      }
    } catch (e) {
      console.error(e);
    }
  }, [firestore, user]);

  useEffect(() => {
    if (user && !fetched) {
      getUserRole();
      setFetched(true);
    }
  }, [fetched, getUserRole, user]);

  return {
    isAdmin,
  };
};
