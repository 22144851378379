import {
  TextField,
  Stack,
  Button,
  IconButton,
  ImageList,
  ImageListItem,
  Box,
  Grid,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import CollectionsIcon from '@mui/icons-material/Collections';
import { FileDropzone } from './FileDropzone';
import CloseIcon from '@mui/icons-material/Close';

export default function PublishPost({
  publish,
  defaultContent,
  defaultEvent,
  defaultImages,
  isUpdate,
  onCancel,
}) {
  const [content, setContent] = useState(defaultContent || '');
  const [isLoading, setIsLoading] = useState(false);
  const [showEvent, setShowEvent] = useState(defaultEvent ? true : false);
  const [showImageUpload, setShowImageUpload] = useState(!!defaultImages);

  const [event, setEvent] = useState(
    defaultEvent
      ? {
          ...defaultEvent,
          startDate: moment.unix(defaultEvent.startDate.seconds),
          endDate: moment.unix(defaultEvent.endDate.seconds),
        }
      : null
  );

  const [images, setImages] = useState(defaultImages || []);

  const handleChange = (event) => {
    setContent(event.target.value);
  };

  const handleEventNameChange = (e) => {
    setEvent({
      ...event,
      name: e.target.value,
    });
  };

  const handleEventLocationChange = (e) => {
    setEvent({
      ...event,
      location: e.target.value,
    });
  };

  const handleEventStartDateChange = (d) => {
    setEvent({
      ...event,
      startDate: d,
    });
  };

  const handleEventEndDateChange = (d) => {
    setEvent({
      ...event,
      endDate: d,
    });
  };

  return (
    <Stack width="100%" spacing={2}>
      <TextField
        multiline
        placeholder="Enter news/announcement text here.."
        onChange={handleChange}
        value={content}
        fullWidth
      />
      {showEvent && (
        <>
          <TextField
            label="Event Name"
            value={event?.name || ''}
            onChange={handleEventNameChange}
          />
          <TextField
            label="Location"
            value={event?.location || ''}
            onChange={handleEventLocationChange}
          />
          <Stack direction="row" spacing={2}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Start Date"
                value={event?.startDate || null}
                onChange={handleEventStartDateChange}
              />
              <DatePicker
                label="End Date"
                value={event?.endDate || null}
                onChange={handleEventEndDateChange}
              />
            </LocalizationProvider>
          </Stack>
        </>
      )}
      {showImageUpload && (
        <>
          {images?.length > 0 && (
            <Box>
              <Grid container spacing={1}>
                {images.map((image, index) => (
                  <Grid item key={index} xs={3}>
                    <Box
                      sx={{
                        backgroundImage: `url(${image})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        height: 160,
                        borderRadius: 2,
                        position: 'relative',
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        sx={{
                          position: 'absolute',
                          bottom: 4,
                          left: 4,
                        }}
                        startIcon={<CloseIcon />}
                        onClick={() => {
                          setImages(
                            images?.filter((_, i) => i !== index) || []
                          );
                        }}
                      >
                        Remove
                      </Button>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}

          <FileDropzone
            onFileUpload={(newImages) => {
              console.log(images, newImages);
              setImages(
                images?.length > 0 ? [...images, ...newImages] : newImages
              );
            }}
          />
        </>
      )}
      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Stack spacing={1} direction="row">
          <IconButton size="small" color={showEvent ? 'primary' : 'default'}>
            <LocationOnIcon onClick={() => setShowEvent(!showEvent)} />
          </IconButton>
          <IconButton
            size="small"
            color={showImageUpload ? 'primary' : 'default'}
          >
            <CollectionsIcon
              onClick={() => setShowImageUpload(!showImageUpload)}
            />
          </IconButton>
        </Stack>
        <Stack spacing={2} direction="row">
          {onCancel !== undefined && (
            <Button
              variant="contained"
              color="inherit"
              onClick={async () => {
                onCancel();
              }}
            >
              Cancel
            </Button>
          )}
          <LoadingButton
            variant="contained"
            onClick={async () => {
              setIsLoading(true);
              await publish(
                content,
                event && showEvent
                  ? {
                      ...event,
                      startDate: Timestamp.fromDate(event.startDate.toDate()),
                      endDate: Timestamp.fromDate(event.endDate.toDate()),
                    }
                  : null,
                images?.length > 0 && showImageUpload ? images : null
              );
              setIsLoading(false);
              setContent(defaultContent || '');
              setEvent(defaultEvent || null);
              setImages(defaultImages || null);
              setShowEvent(false);
              setShowImageUpload(false);
            }}
            disabled={content.trim().length === 0}
            loading={isLoading}
          >
            {isUpdate ? 'Update' : 'Publish'} Post
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
}
