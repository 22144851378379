import { Box, Stack, useMediaQuery } from '@mui/material';
import SideBar from './SideBar';

export const PageContainer = ({ children, hideSidebar }) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <Stack padding={6} alignItems="center">
      <Stack
        justifyContent="center"
        direction="row"
        display="flex"
        maxWidth={1200}
        spacing={6}
        width="100%"
      >
        {isDesktop && !hideSidebar && <SideBar />}
        <Box flex={1}>{children}</Box>
      </Stack>
    </Stack>
  );
};
