import { useEffect, useState } from 'react';
import NavBar from '@/components/NavBar';
import { Stack, Typography } from '@mui/material';
import Head from 'next/head';
import { Timestamp } from 'firebase/firestore';
import { stripHtml } from 'string-strip-html';
import { usePosts } from '@/hooks/usePosts';
import { useAuth } from '@/hooks/useAuth';
import Island from '@/components/IslandContainer';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import PostItem from '@/components/PostItem';
import PublishPost from '@/components/PublishPost';
import { blueGrey } from '@mui/material/colors';
import { useUserRoles } from '@/hooks/useUserRoles';
import { PageContainer } from '@/components/PageContainer';
import EmptyData from '@/images/empty_data.png';
import Image from 'next/image';

export default function HomePage() {
  const { isLoggedIn } = useAuth();
  const { getAllPosts, createPost } = usePosts();
  const { isAdmin } = useUserRoles();
  const [posts, setPosts] = useState([]);

  const publish = async (content, event, images) => {
    const post = {
      content: stripHtml(content).result,
      event,
      ...(images && { images }),
      publishedAt: Timestamp.now(),
    };

    await createPost(post);
    await fetchPosts();
  };

  const fetchPosts = async () => {
    const posts = await getAllPosts();
    setPosts(posts || []);
  };

  const updatePosts = () => {
    fetchPosts();
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchPosts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <>
      <Head>
        <title>Home</title>
      </Head>
      <main>
        <NavBar />
        <PageContainer>
          <Stack spacing={4}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <AnnouncementOutlinedIcon sx={{ color: blueGrey[800] }} />
              <Typography variant="h5" color={blueGrey[800]}>
                News &amp; Announcements
              </Typography>
            </Stack>
            {isAdmin && (
              <Island isAdmin>
                <PublishPost publish={publish} isUpdate={false} />
              </Island>
            )}
            {posts?.map((post) => (
              <PostItem key={post.id} post={post} updatePosts={updatePosts} />
            ))}
            {posts?.length === 0 && (
              <Stack alignItems="center" p={4} spacing={4}>
                <Image
                  src={EmptyData}
                  width={400}
                  height={400}
                  alt="Empty Page"
                />
                <Typography variant="h6" color={blueGrey[800]}>
                  No posts yet
                </Typography>
              </Stack>
            )}
          </Stack>
        </PageContainer>
      </main>
    </>
  );
}
