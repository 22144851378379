import moment from 'moment';
import { Timestamp } from 'firebase/firestore';

/**
 * Takes in an object with user data. For each of the values in the object,
 * check if the value is a moment object. If it is, turn it into firebase timestamp.
 * If the value is an array, loop through the objects in the array and check for the same
 * thing with its values.
 */
export const cleanUserData = (userData) => {
  const cleanedUserData = { ...userData };

  for (const [key, value] of Object.entries(cleanedUserData)) {
    if (typeof value === 'object' && moment.isMoment(value)) {
      cleanedUserData[key] = Timestamp.fromDate(value.toDate());
    }

    if (Array.isArray(value)) {
      value.forEach((value1, index) => {
        value[index] = cleanUserData(value1);
      });
    }
  }

  return cleanedUserData;
};
