import { useAuth } from '@/hooks/useAuth';
import {
  AppBar,
  Typography,
  Button,
  Toolbar,
  Stack,
  useMediaQuery,
  Drawer,
  IconButton,
} from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useUserRoles } from '@/hooks/useUserRoles';

export default function NavBar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return (
    <>
      <AppBar component="nav">
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: {
                xs: 'none',
                sm: 'block',
                justifyContent: 'space-between',
              },
            }}
          >
            JONDONERO FAMILY
          </Typography>
          {isDesktop && (
            <Stack spacing={1} direction="row">
              <MenuItems />
            </Stack>
          )}
          {!isDesktop && (
            <>
              <IconButton onClick={() => setMenuOpen(true)}>
                <MenuIcon sx={{ color: '#fff' }} />
              </IconButton>
              <Drawer
                anchor="top"
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
              >
                <MenuItems isDrawer />
              </Drawer>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
}

const MenuItems = ({ isDrawer }) => {
  const { logout } = useAuth();
  const router = useRouter();
  const { isAdmin } = useUserRoles();

  return (
    <>
      <MenuItem isDrawer={isDrawer} href="/app">
        Home
      </MenuItem>
      <MenuItem isDrawer={isDrawer} href="/app/person">
        Profile
      </MenuItem>
      <MenuItem isDrawer={isDrawer} href="/app/events">
        Events
      </MenuItem>
      <MenuItem isDrawer={isDrawer} href="/app/family-tree">
        Family Tree
      </MenuItem>
      <MenuItem isDrawer={isDrawer} href="/app/donations">
        Mortuary & Donations
      </MenuItem>
      {isAdmin && (
        <MenuItem isDrawer={isDrawer} href="/app/admin" isAdmin>
          Admin
        </MenuItem>
      )}
      <MenuItem
        isDrawer={isDrawer}
        onClick={() => {
          logout();
          router.push('/');
        }}
      >
        Log Out
      </MenuItem>
    </>
  );
};

const MenuItem = ({ href, onClick, children, isDrawer, isAdmin }) => {
  return (
    <Button
      component="a"
      LinkComponent={Link}
      href={href}
      onClick={onClick}
      sx={{
        color: !isDrawer ? '#fff' : undefined,
        fontWeight: isAdmin ? 'bold' : undefined,
      }}
    >
      {children}
    </Button>
  );
};
