import { Paper, Stack } from '@mui/material';
import AdminStripe from '@/components/AdminStripe';

export default function Island({ isAdmin, children }) {
  return (
    <Paper elevation={3} sx={{ overflow: 'hidden' }}>
      {isAdmin && <AdminStripe />}
      <Stack spacing={2} padding={2}>
        {children}
      </Stack>
    </Paper>
  );
}
